<template>
	<!-- 检验单申请 -->
	<div :class="themeClass" class="wid" v-loading="Showloading">
		<div class="box">
			
		
		<div class="allOrder-box margin-t-20">
			<div class="dip all-title font-size18 font-color-333 font-weight700 pr">
				检验单申请
				<div class="inputBor margin-r-10 margin-l-35" style="font-size: 10px !important;">
					<el-date-picker v-model="beginDate" type="date" placeholder="请选择开始日期">
					</el-date-picker>
					<span style="font-size: 14px !important;">至</span>
					<el-date-picker v-model="endDate" type="date" placeholder="请选择结束日期">
					</el-date-picker>
				</div>
				<div class="allOrder-btn">
					<div  class="otns pointer" @click="getList">查询</div>
					<div class="otn pointer" @click="add" >新增检验申请</div>
				</div>
			</div>
			<div class="tab margin-t-13 flex-row margin-b-10">
				<div class="flex-row">
					<div class="tabItem text-center" :class="tabSelect == 2 ? 'bg-fff color-theme border-lr-F2F2F2' : ''" @click="tabClick(2)">28天电子检验单</div>
					<div class="tabItem text-center" :class="tabSelect == 1 ? 'bg-fff color-theme border-lr-F2F2F2' : ''" @click="tabClick(1)">3天电子检验单</div>
					<div class="tabItem text-center" :class="tabSelect == 3 ? 'bg-fff color-theme border-lr-F2F2F2' : ''" @click="tabClick(3)">合格证</div>
				</div>
				<div class="tab-right"></div>
			</div>
		</div>
		<div class="orderd" style="color: #666666;border: none;">
			<div style="margin-left: 30px;">
				<span>温馨提示：</span>
				<span v-if="tabSelect ==2">1.该检验报告单为28天检验报告单，提货出厂28天后方可申请。</span>
				<span v-if="tabSelect ==1">1.该检验报告单为3天检验报告单，提货出厂一年内的3天电子检验单方可申请。</span>
				<span v-if="tabSelect ==3">1.该检验报告单为合格证检验报告单，提货出厂一年内的合格证方可申请。</span>
			</div>
			<div style="margin-left: 100px;">
				<span v-if="tabSelect ==2 || tabSelect ==1">2.已申请的检验报告单如2个工作日内未出结果，请您及时拨打客服热线或联系在线客服为您进行处理。</span>
			    <span v-if="tabSelect ==3">2.已申请的合格证如2个工作日内未出结果，请您及时拨打客服热线或联系在线客服为您进行处理。</span>
			</div>
		</div>
		<div class="orderd font-size14 margin-b-15" v-for="(item, index) in oderList" :key="index">
			<div class="diso margin-b-10">
				<div class="font-color-000 font-size13" style="line-height: 22px;">申请单号：{{item.fBillNumber}}</div>
				<div class="margin-l-10 font-color-000 font-size13" style="line-height: 22px;">{{item.fPlateNumber}}</div>
				<div class="margin-l-10">
					<span class="tipsd" :class="{
					  bay: item.fApproveStatus ==1,
					  bar: item.fApproveStatus ==0,
					  bab: item.fApproveStatus == 254,
									gre: item.fApproveStatus == 255,
					}">{{ item.fApproveStatusName }}</span>
				</div>
			</div>
			<div class="diso" style="margin-bottom: 9px">
				<div class="font-color-000 wid8">
					{{ item.fGoodsFullName }}
					
				</div>
				<div class="font-color-666 wid2 ter">
					出厂编号：{{ item.fWareNumber }}
				</div>
			</div>
			<div class="diso" style="margin-bottom: 9px">
				<div class="font-color-666 wid8">
					<span>工程名称: {{ item.fqCustomerName }}</span>					
				</div>
				<div class="font-color-666 wid2 ter">
					发货日期：{{ item.fDeliveryData }}
				</div>
			</div>
			<div class="diso">
				<div class="font-color-666 wid8">
					<span>申请时间:{{item.fCreateTime}}</span>
				
				</div>
				<div class="font-color-666 wid2 ter">
					<span>申请数量: {{ item.fAmount }}{{item.fUnitName}}</span>
				</div>
			</div>
			<div class="orderstate flex-row">
				<span style="font-size: 22px;" class="iconfont deletIconBtn font-size22 font-color-000 pointer" v-if="item.fApproveStatus==0"  @click="del(item)">&#xe744;</span>
				<span style="font-size: 20px;" class="iconfont editIconBtn font-size20 font-color-000 pointer" v-if="item.fApproveStatus==0" @click="edi(item)">&#xe62b;</span>
				<span style="border:1px solid #0173FE;border-radius: 24px;padding: 0px 10px;display: block;width:87px;text-align: center;"class="iconfont downcolr editIconBtn font-size14  pointer margin-l-10 "  v-if="item.fCreateStatus==1" @click="down(item)">下载化验单</span>
				<span style="border:1px solid #0173FE;border-radius: 24px;padding: 0px 10px;display: block;width:87px;text-align: center;"class="iconfont downcolr editIconBtn font-size14  pointer margin-l-10" v-if="item.fApproveStatus==0"  @click="submitForm(item)">提交审核</span>
			</div>		
		</div>
		<div v-if="oderList.length==0">
			<div class="flex-row-center-center width-fill openACcount">
				<svg-icon icon-class="NoRightsAndInterests" />
			</div>
		</div>
		<div class="flex-row-center-flex-end margin-t-20 margin-b-30">
			<pagination  @handleCurrentChange="handleCurrentChange" :currentPage="currentPage" :pagesize="pagesize" :total="total"></pagination>
		</div>
	</div>
	</div>
	</div>
	</div>
</template>

<script>
	import pagination from '@/components/pagination/pagination.vue';
	import {
		mapGetters
	} from "vuex";
	export default {
		components: {
			pagination
		},
		computed: {
			...mapGetters(["getThemeName"]),
			themeClass() {
				return `theme-${this.getThemeName}`;
			},
		},
		data() {
			return {
        Showloading: false,
				total: 0, //总条数
				pagesize: 9, //每页显示条目个数
				currentPage: 1, //当前页
				searchDate: [new Date(new Date().setTime(new Date().getTime() - 3600 * 1000 * 24 * 30)), new Date()],
				activeName: "sevendays",
				oderList: [],
				beginDate:"",
				endDate:"",
				tabSelect: 2, //tab选中项
			};
		},
		mounted() {
			const end = new Date();
			const start = new Date();
			this.beginDate = new Date(start.setTime(start.getTime() - 3600 * 1000 * 24 * 30));
			this.endDate = end;
			this.getList();
		},
		methods: {
			// tab切换
			tabClick(val) {
				this.tabSelect = val;
				this.getList();
			},
			submitForm(item){
				let params =[{
						  "fBillID":item.fBillID,
				}];				
				this.ApiRequestPut(
					'/api/mall/ebsale/check-list-apply-record/submit-auditing',params
				).then(
					(res) => {
						this.getList();
					},
					(error) => {
					}
				);
			},
			down(path){
        this.Showloading = true
			setTimeout(() => {
			  this.Showloading = false
			  let url=""
			  if(path.fIsID ==0){
				 url=path.fAttFileName
			  }else if(path.fIsID ==1){  
				  let urls =window.API_SERVER+'api/henghe/blob/get-file-by-id/';
				  url = urls + path.fAttFileName 
			  }
			  window.open(url,'_blank',) 
			}, 500); 
			},
			del(item){
				 this.$confirm('确定删除此检验申请单?', '提示', {
				          confirmButtonText: '确定',
				          cancelButtonText: '取消',
				          type: 'warning'
				        }).then(() => {
							this.getDe(item)
				        }).catch(() => {
				             
				        });
			},
			getDe(item){
				this.ApiRequestPost(
					"/api/mall/ebsale/check-list-apply-record/delete", {
					  "fBillID":item.fBillID,
					  "frv": item.frv,
					  "rowStatus":this.rowStatus_delete
					}
				).then(
					(result) => {
						this.getList();
					},
					(error) => {}
				);
			},
			edi(item){
				this.$router.push({
					path: "/businessme/editInsOrder",
					query: {
						id: item.fBillID,
					},
				});
			},
			handleCurrentChange(val) {
				this.currentPage = val;
				this.getList();
			},
			getList() {
				console.log('当前页',this.currentPage)
				if(new Date(this.beginDate) > new Date(this.endDate)){
					this.$message.info('开始时间不能大于结束时间！')
					return;
				}
				this.ApiRequestPostNOMess(
					"/api/mall/ebsale/check-list-apply-record/get-list", {
						fApplyType:this.tabSelect,
						skipCount: (this.currentPage - 1) * this.pagesize,
						maxResultCount: this.pagesize,
						beginTime:this.getDate(this.beginDate),
						endTime:this.getDate(this.endDate),
					}
				).then(
					(result) => {
						if (result.obj.items.length > 0) {
							for (let item of result.obj.items) {
								item.fDeliveryData = this.getDate(item.fDeliveryData);
							}
							this.oderList = [];
							console.log('获取检验申请单时间',result.obj.items)
							for(var i =0 ; i < result.obj.items.length;i++){
								result.obj.items[i].fCreateTime = this.getTime(result.obj.items[i].fCreateTime);
							}
							this.oderList = result.obj.items;
							this.total = result.obj.totalCount
							
						}else{
							this.oderList = result.obj.items;
							this.total = result.obj.totalCount
						}
					},
					(error) => {

					}
				);
			},
			query(){
				this.getList();
			},
			add() {
				this.$router.push({
					path: "/businessme/addInsOrder",
					query: "",
				});
			},
		},
	};
</script>

<style lang="scss" scoped>
	
	.orderstate {
		
		margin-top: 10px;
	}
	.inputBor {
		width: 380px;
		display: flex;
		border: 1px solid #f2f2f2;
		font-weight: 400;
		font-size: 14px;
		/deep/.el-input__inner {
			border: 0 !important;
		}
		/deep/ .el-input__suffix-inner {
			// line-height: 50px;
			display: none;
		}
	
		span {
			line-height: 30px;
			font-size: 14px !important;
		}
	}
	.wid{
		width:100%;
	}
	.box{
		width: 100%;
		height: calc(100% - 10px);
		background-color: #FFFFFF;
	}
	.flexd{
		display: -webkit-flex; /* Safari */
		display: flex;
		
	}
	.flexend{
		 justify-content: flex-end;
	}
	.downcolr{
		@include themify($themes) {
			color: themed("themes_color");
		}
	}
	.queryBtn {
	  width: 75px;
	  height: 32px;
	  line-height: 32px;
	  @include themify($themes) {
	  	background-color: themed("themes_color");
	  }
	}
	/deep/ .el-tabs__header {
		margin: 0;
	}

	/deep/ .el-tabs__item {
		padding: 0;
		width: 75px;
		height: 32px;
		line-height: 32px;
		text-align: center;
	}

	/deep/ .el-tabs__active-bar {
		display: none;
	}

	/deep/ .el-tabs__nav-wrap::after {
		background-color: transparent;
	}

	/deep/ .el-input__inner {
		height: 32px;
		line-height: 32px;
	}

	/deep/ .el-input__icon {
		line-height: 22px;
	}

	/deep/ .el-tabs__item.is-active {
		color: #fff;

		@include themify($themes) {
			background-color: themed("themes_color");
		}
	}

	.dip {
		display: flex;
	}

	.orderd {
		padding:10px;
		margin-top:20px;
		width: 885px;
		// height: 40px;
		border-radius: 5px;
		border: 1px solid #f2f2f2;
	}

	.diso {
		display: flex;
	}

	.wid8 {
		width: 80%;
	}

	.wid2 {
		width: 20%;
	}

	.ter {
		text-align: right;
	}

	.tipsd {
		margin-left: 3px;
		width: 43px;
		height: 17px;
		border-radius: 2px;
		font-size: 12px;
		font-weight: 400;
		color: #ffffff;
		padding-left: 5px;
		padding-right: 5px;
	}

	.bay {
		background: #ff9900;
	}

	.bar {
		background: #ff0000;
	}

	.bab {
		background: #0173fe;
	}
	.gre{
		background: #32da8c;
	}

	.ml15 {
		margin-left: 15px;
	}
	
	.otns{
		text-align: center;
		width: 60px;
		height: 30px;
		line-height: 30px;
		background: #0173fe;
		border-radius: 2px;
		font-size: 14px;
		right: 130px;
		// position: absolute;
		font-weight: 400;
		color: #ffffff;
	}
	
	.otn {
		text-align: center;
		width: 110px;
		height: 30px;
		line-height: 30px;
		background: #0173fe;
		border-radius: 2px;
		font-size: 14px;
		right: 0;
		// position: absolute;
		font-weight: 400;
		color: #ffffff;
	}
	.otn2 {
		text-align: center;
		width: 110px;
		height: 30px;
		line-height: 30px;
		background: #0173fe;
		border-radius: 2px;
		font-size: 14px;
		margin-right: 10px;
		font-weight: 400;
		color: #ffffff;
	}
	
	.pr {
		position: relative;
	}
	.allOrder-btn {
		display: flex;
		// position: relative;
		// left: 295px;
	}
	
	.allOrder-btn div:nth-child(1) {
		width: 75px;
		height: 32px;
		margin-right: 5px;
		text-align: center;
		line-height: 32px;
	}
	
	.allOrder-btn div:nth-child(2) {
		width: 110px;
		height: 32px;
		text-align: center;
		line-height: 32px;
	}
	.tab {
		width: 100%;
		height: 40px;
		background-color: #f8f9fe;
		border: 1px solid #f2f2f2;
		border-bottom: none;
	}
	
	.tabItem {
		width: 145px;
		height: 40px;
		line-height: 40px;
		box-sizing: border-box;
		cursor: pointer;
	}
	
</style>
